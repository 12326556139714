import { defineStore } from 'pinia'

import { getAgency, IAgency, updateAgency } from 'lib/api/modules/partner/creators/agency'
import { CurrencyIcon } from 'lib/types/currency'
import { useReferralStore } from './referral'

interface State {
  isFetchingData: boolean
  data: null | IAgency
  disabled: {
    internaleCpm: boolean
    externalCpm: boolean
    darkMarketInternalCpm: boolean
    darkMarketExternalCpm: boolean
  }
  currencySign: null | CurrencyIcon
  isFormUpdating: boolean
}

const referralStore = useReferralStore()

export const useAgencyStore = defineStore({
  id: 'partner-agency',

  state: (): State => ({
    isFetchingData: false,
    data: null,
    disabled: {
      internaleCpm: false,
      externalCpm: false,
      darkMarketInternalCpm: false,
      darkMarketExternalCpm: false,
    },
    currencySign: null,
    isFormUpdating: false,
  }),

  actions: {
    async getData () {
      if (this.data) return

      try {
        this.isFetchingData = true
        const res = await getAgency()
        this.data = res
        this._checkIfFilled()

        const currencyCode = res.wallet.currency.toLocaleUpperCase() as keyof typeof CurrencyIcon
        this.currencySign = CurrencyIcon[currencyCode]

        await referralStore.fetchReferral()
        if (this.data.id === 1) {
          await referralStore.fetchReferralCreators()
        }
      }
      finally {
        this.isFetchingData = false
      }
    },

    _checkIfFilled () {
      if (!this.data) {
        return
      }
      this.disabled.internaleCpm = !Object.values(this.data.internalCpm).every(val => !val)
      this.disabled.externalCpm = !Object.values(this.data.externalCpm).every(val => !val)
      this.disabled.darkMarketInternalCpm = !Object.values(this.data.darkMarketInternalCpm).every(val => !val)
      this.disabled.darkMarketExternalCpm = !Object.values(this.data.darkMarketExternalCpm).every(val => !val)
    },

    async updateData () {
      if (!this.data || this.isFormUpdating) return

      try {
        this.isFormUpdating = true
        await updateAgency(this.data)
        this._checkIfFilled()
      }
      finally {
        this.isFormUpdating = false
      }
    },
  },
})
