import { IAgency } from '../types'

import { IAgencyPayload } from './types'

export const fromDataToPayload = (data: IAgency): IAgencyPayload => ({
  streamers_participate: data.streamersParticipate,
  use_dark_market: data.useDarkMarket,
  commission: data.commission || null,
  external_cpm: {
    video: data.externalCpm.video || null,
    pip_video: data.externalCpm.pip || null,
    custom: data.externalCpm.custom || null,
    interactive: data.externalCpm.interactive || null,
    leaderboard: null,
    yandex_fs: null,
  },
  internal_cpm: {
    video: data.internalCpm.video || null,
    pip_video: data.internalCpm.pip || null,
    custom: data.internalCpm.custom || null,
    interactive: data.internalCpm.interactive || null,
    leaderboard: null,
    yandex_fs: null,
  },
  dark_market_external_cpm: {
    video: data.darkMarketExternalCpm.video || null,
    pip_video: data.darkMarketExternalCpm.pip || null,
    custom: data.darkMarketExternalCpm.custom || null,
    interactive: data.darkMarketExternalCpm.interactive || null,
    leaderboard: null,
    yandex_fs: null,
  },
  dark_market_internal_cpm: {
    video: data.darkMarketInternalCpm.video || null,
    pip_video: data.darkMarketInternalCpm.pip || null,
    custom: data.darkMarketInternalCpm.custom || null,
    interactive: data.darkMarketInternalCpm.interactive || null,
    leaderboard: null,
    yandex_fs: null,
  },
  ignore_categories: data.ignoredCategories,
})
