import { IAgency } from '../types/IAgency'

import { IAgencyResponse } from './types'

export const fromResponseToData = (response: IAgencyResponse): IAgency => ({
  id: response.id,
  title: response.title,
  description: response.description,
  streamersParticipate: response.streamers_participate,
  useDarkMarket: response.use_dark_market,
  commission: response.commission || '',
  externalCpm: {
    video: response.cost_per_mille.external_cpm.video || '',
    pip: response.cost_per_mille.external_cpm.pip_video || '',
    custom: response.cost_per_mille.external_cpm.custom || '',
    interactive: response.cost_per_mille.external_cpm.interactive || '',
  },
  internalCpm: {
    video: response.cost_per_mille.internal_cpm.video || '',
    pip: response.cost_per_mille.internal_cpm.pip_video || '',
    custom: response.cost_per_mille.internal_cpm.custom || '',
    interactive: response.cost_per_mille.internal_cpm.interactive || '',
  },
  darkMarketExternalCpm: {
    video: response.cost_per_mille.dark_market_external_cpm.video || '',
    pip: response.cost_per_mille.dark_market_external_cpm.pip_video || '',
    custom: response.cost_per_mille.dark_market_external_cpm.custom || '',
    interactive: response.cost_per_mille.dark_market_external_cpm.interactive || '',
  },
  darkMarketInternalCpm: {
    video: response.cost_per_mille.dark_market_internal_cpm.video || '',
    pip: response.cost_per_mille.dark_market_internal_cpm.pip_video || '',
    custom: response.cost_per_mille.dark_market_internal_cpm.custom || '',
    interactive: response.cost_per_mille.dark_market_internal_cpm.interactive || '',
  },
  ignoredCategories: response.ignore_categories,
  wallet: response.wallet,
})
